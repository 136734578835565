global.$ = global.jQuery = require('jquery');

require("@fancyapps/fancybox");
import 'foundation-sites';

Foundation.addToJquery($);

$(document).foundation();
/*
	* BG-IMG
	* Create background-image from html image tag with javscript
	* Usage. Add .js-bg to element to which you want to add background-image. Add .js-bg-img to image which will be used as background-image.
*/
(function() {
	// $(".js-bg").each(function() {
	// 	var el = $(this);
	// 	var img = el.find(".js-bg-img");
	// 	var img_path = img.attr("src");

	// 	img.addClass("js-bg-img-hidden");
	// 	el.css({ "background-image": "url(" + img_path + ")" });
	// });

	$("form.filter select").on('change', function() {
		$(this).closest('form').submit();
	});

    $(".fancybox-video").fancybox({
        'width' : 800,
        'height' : 700,
    });

    if (window.location.hash) {
        var hash = window.location.hash;
        if ($(hash).length) {
            $('html, body').animate({
                scrollTop: $(hash).offset().top - 70
            }, 500);
        }
    }

    $('[class*="scroll-link"]').each(function() {
	    $(this).on('click', function (e) {
	    	var hash = $(this).attr('href').split('#')[1];

	    	if (!hash) {
	    		return true;
	    	}

	    	var $target = $('#' + hash);
    		if ($target.length) {
		    	e.preventDefault();
		    	$('html, body').animate({
		    		scrollTop: $('#' + hash).offset().top - 70
		    	}, 1000);
		    }
		});
    });

    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            this.classList.toggle("accordianActive");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
    }

    let opennav = document.querySelector('.open-nav');
    if (opennav) {
    	opennav.addEventListener("click", function() {
    		openNav();
    	});
    }

    let closenav = document.querySelector('.close-nav');
    if (closenav) {
    	closenav.addEventListener("click", function() {
    		closeNav();
    	});
    }

    let menuCaret = document.querySelectorAll(".hasMenuCaret");
    menuCaret.forEach((menu) => {
      menu.onclick = function () {
            let content = this.nextElementSibling;
            console.log(content.classList.contains('show'));
            if (content.classList.contains('show')) {
                content.classList.remove('show');
            }
            else {
                content.classList.add('show');
            }
        };
    });

    function openNav() {
        document.getElementById("mySidenav").classList.add('open-sub-nav');
    }

    function closeNav() {
        document.getElementById("mySidenav").classList.remove('open-sub-nav');
    }
})();